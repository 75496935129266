/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { graphql } from "gatsby";
import { Fragment, useState } from "react";

import {
  getArtistName,
  getArtworkPath,
  getExhibitionPath,
  getPublicationPath,
  getViewingRoomPath,
} from "utils";
import {
  Heading,
  Layout,
  Link,
  PortableText,
  ProtectedImage,
} from "components";
import { ChevronLeft, ChevronRight } from "components/icons";
import { getIsShortScreen } from "utils";

const visuallyHidden = {
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  width: "1px",
};

function FeaturedItems({
  selectedFeaturedIndex,
  setSelectedFeaturedIndex,
  featuredItems = [],
}) {
  const featuredItem = featuredItems[selectedFeaturedIndex];

  const next = () => {
    setSelectedFeaturedIndex(
      (selectedFeaturedIndex + featuredItems.length + 1) % featuredItems.length
    );
  };

  const previous = () => {
    setSelectedFeaturedIndex(
      (selectedFeaturedIndex + featuredItems.length - 1) % featuredItems.length
    );
  };

  const styles = {
    button:
      ({ direction }) =>
      (_theme) => ({
        cursor: "pointer",
        position: "absolute",
        top: "50%",
        [direction]: "16px",
        width: "32px",
        height: "32px",
        bg: "transparent",
        border: "none",
        p: 0,
        m: 0,
        mt: "-16px",

        "> svg": {
          fill: "primary",
          height: "100%",
        },
      }),

    dot:
      ({ isActive }) =>
      (_theme) => ({
        borderRadius: "100%",
        border: "none",
        bg: isActive ? "primary" : "secondary",
        cursor: "pointer",
        height: "8px",
        width: "8px",
        p: 0,
        my: 0,
        mx: "xxs",
      }),
  };

  return (
    <div
      sx={{
        px: "80px",
        position: "relative",
        mb: "xl",
      }}
    >
      {featuredItem._type === "artwork" && (
        <FeaturedArtwork _id={featuredItem._id} {...featuredItem} />
      )}
      {featuredItem._type === "exhibition" && (
        <FeaturedExhibition {...featuredItem} />
      )}
      {featuredItem._type === "publication" && (
        <FeaturedPublication {...featuredItem} />
      )}
      {featuredItem._type === "viewing_room" && (
        <FeaturedViewingRoom {...featuredItem} />
      )}

      {featuredItems.length > 1 && (
        <Fragment>
          <button
            type="button"
            onClick={previous}
            sx={styles.button({ direction: "left" })}
          >
            <ChevronLeft />
          </button>

          <button
            type="button"
            onClick={next}
            sx={styles.button({ direction: "right" })}
          >
            <ChevronRight />
          </button>

          <div
            sx={{
              mt: "m",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {featuredItems.map((_, index) => (
              <button
                key={index}
                type="button"
                onClick={() => setSelectedFeaturedIndex(index)}
                sx={styles.dot({ isActive: index === selectedFeaturedIndex })}
              >
                <span sx={visuallyHidden}>{index}</span>
              </button>
            ))}
          </div>
        </Fragment>
      )}
    </div>
  );
}

function FeaturedItem({
  imageContent,
  rowReverse = false,
  children,
  ...props
}) {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: ["column", rowReverse ? "row-reverse" : "row"],
        justifyContent: "center",
        mx: "-s",
      }}
      {...props}
    >
      <div
        sx={{
          mx: "s",
          mb: ["m", 0],
        }}
      >
        {imageContent}
      </div>

      <div sx={{ flexBasis: [null, "70%", "40%"], mx: "s" }}>{children}</div>
    </div>
  );
}

function FeaturedArtwork(artwork) {
  const details = artwork?._rawHomepageDetails || artwork?._rawDetails;
  const isShortScreen = getIsShortScreen();

  return (
    <FeaturedItem
      rowReverse={artwork.rowReverse}
      imageContent={
        <Link to={getArtworkPath(artwork)}>
          <ProtectedImage
            image={
              isShortScreen
                ? artwork.image__short.asset.gatsbyImageData
                : artwork.image.asset.gatsbyImageData
            }
            alt={artwork.name}
          />
        </Link>
      }
    >
      <Fragment>
        <Heading mb="xs" sx={{ fontSize: "24px" }}>
          <Link to={getArtworkPath(artwork)}>
            {artwork.name}
            {artwork.work_date && ` (${artwork.work_date})`}
          </Link>
        </Heading>

        <Text as="p">
          {[artwork.artist.first_name, artwork.artist.last_name]
            .filter(Boolean)
            .join(" ")}
        </Text>

        {details && <PortableText content={details} sx={{ mb: "m" }} />}
      </Fragment>
    </FeaturedItem>
  );
}

function FeaturedExhibition(exhibition) {
  const isShortScreen = getIsShortScreen();
  const title = exhibition?.homepage_name || exhibition?.name;
  const description =
    exhibition?._rawDescriptionHome || exhibition?._rawDescription;

  const featuredArtwork =
    exhibition?.homepage_artwork ||
    exhibition?.featured_artwork ||
    exhibition.artworks[0];

  return (
    <FeaturedItem
      rowReverse={exhibition.rowReverse}
      imageContent={
        <Link to={getExhibitionPath(exhibition)}>
          {featuredArtwork && (
            <ProtectedImage
              image={
                isShortScreen
                  ? featuredArtwork?.work_image__short?.asset?.gatsbyImageData
                  : featuredArtwork?.work_image?.asset?.gatsbyImageData
              }
              alt={title}
              sx={{ mb: "xs" }}
            />
          )}

          {featuredArtwork && (
            <Text as="p" sx={{ fontSize: 1, m: 0 }}>
              {[
                getArtistName(featuredArtwork.artist),
                featuredArtwork?.work_title || featuredArtwork?.name,
                featuredArtwork?.work_date,
              ]
                .filter(Boolean)
                .join(", ")}
            </Text>
          )}
        </Link>
      }
    >
      <Fragment>
        <Heading mb="xs" sx={{ fontSize: "24px" }}>
          <Link to={getExhibitionPath(exhibition)}>{title}</Link>
        </Heading>

        <Text as="p">
          {[exhibition.start_date, exhibition.end_date]
            .filter(Boolean)
            .join(" - ")}
        </Text>

        {description && <PortableText content={description} sx={{ mb: "m" }} />}
      </Fragment>
    </FeaturedItem>
  );
}

function FeaturedViewingRoom(viewingRoom) {
  const isShortScreen = getIsShortScreen();
  const title = viewingRoom?.homepage_title || viewingRoom?.title;
  const content = viewingRoom?.homepageContent || viewingRoom?.content;
  const featuredArtwork = viewingRoom.artworks[0];

  return (
    <FeaturedItem
      rowReverse={viewingRoom.rowReverse}
      imageContent={
        <Link to={getViewingRoomPath(viewingRoom)}>
          {featuredArtwork && (
            <ProtectedImage
              image={
                isShortScreen
                  ? featuredArtwork?.work_image__short?.asset?.gatsbyImageData
                  : featuredArtwork?.work_image?.asset?.gatsbyImageData
              }
              alt={title}
              sx={{ mb: "xs" }}
            />
          )}

          {featuredArtwork && (
            <Text as="p" sx={{ fontSize: 1, m: 0 }}>
              {[
                getArtistName(featuredArtwork.artist),
                featuredArtwork?.work_title || featuredArtwork?.name,
                featuredArtwork?.work_date,
              ]
                .filter(Boolean)
                .join(", ")}
            </Text>
          )}
        </Link>
      }
    >
      <Fragment>
        <Heading mb="xs" sx={{ fontSize: "24px" }}>
          <Link to={getViewingRoomPath(viewingRoom)}>{title}</Link>
        </Heading>

        {content && <PortableText content={content} sx={{ mb: "m" }} />}
      </Fragment>
    </FeaturedItem>
  );
}

function FeaturedPublication(publication) {
  const isShortScreen = getIsShortScreen();
  const details = publication?._rawDetailsHome || publication?._rawDetails;

  return (
    <FeaturedItem
      rowReverse={publication.rowReverse}
      imageContent={
        <Link to={getPublicationPath(publication)}>
          <ProtectedImage
            image={
              isShortScreen
                ? publication.image__short.asset.gatsbyImageData
                : publication.image.asset.gatsbyImageData
            }
            alt={publication.title}
          />
        </Link>
      }
    >
      <Heading mb="xs" sx={{ fontSize: "24px" }}>
        <Link to={getPublicationPath(publication)}>{publication.title}</Link>
      </Heading>

      {publication?.publication_date_site && (
        <Text as="p">{publication.publication_date_site}</Text>
      )}

      {details && <PortableText content={details} sx={{ mb: "m" }} />}
    </FeaturedItem>
  );
}

function IndexPage({
  data: {
    sanityHomePage: { content, featured_items },
  },
}) {
  const [selectedFeaturedIndex, setSelectedFeaturedIndex] = useState(0);

  const resetPage = () => {
    setSelectedFeaturedIndex(0);
  };

  return (
    <Layout onLogoClick={resetPage}>
      <Heading sx={visuallyHidden}>Austin / Desmond Fine Art</Heading>

      {featured_items.length > 0 && (
        <FeaturedItems
          selectedFeaturedIndex={selectedFeaturedIndex}
          setSelectedFeaturedIndex={setSelectedFeaturedIndex}
          featuredItems={featured_items}
        />
      )}

      {content && (
        <PortableText
          content={content}
          sx={{
            maxWidth: "570px",
            mx: "auto",
            textAlign: "center",
            mb: "l",
            fontSize: 3,
            pt: "mm",
            pb: "xs",
            position: "relative",

            "&:before, &:after": {
              content: '""',
              display: "block",
              height: "1px",
              bg: "secondary",
              width: "30px",
              left: "50%",
              ml: "-15px",
              position: "absolute",
            },

            "&:before": {
              top: 0,
            },

            "&:after": {
              bottom: 0,
            },
          }}
        />
      )}
    </Layout>
  );
}

export const query = graphql`
  query HomePage {
    sanityHomePage(_id: { eq: "home_page" }) {
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      featured_items {
        ... on SanityArtwork {
          _id
          name
          _type
          artist {
            first_name
            last_name
          }
          work_date
          image: work_image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, height: 600, fit: FILLMAX)
            }
          }
          image__short: work_image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, height: 500, fit: FILLMAX)
            }
          }
          _rawDetails(resolveReferences: { maxDepth: 10 })
          _rawHomepageDetails(resolveReferences: { maxDepth: 10 })
          homepage_work_title
        }

        ... on SanityPublication {
          _id
          title
          _type
          _rawDetails(resolveReferences: { maxDepth: 10 })
          image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, height: 600, fit: FILLMAX)
            }
          }
          image__short: image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, height: 500, fit: FILLMAX)
            }
          }
          _rawHomepageDetails(resolveReferences: { maxDepth: 10 })
          homepage_title
        }

        ... on SanityExhibition {
          _id
          name
          _type
          _rawDescriptionHome(resolveReferences: { maxDepth: 10 })
          year: start_date(formatString: "YYYY")
          start_date(formatString: "Do MMMM YYYY")
          end_date(formatString: "Do MMMM YYYY")

          homepage_artwork {
            _id
            name
            work_image {
              asset {
                gatsbyImageData(layout: CONSTRAINED, height: 600, fit: FILLMAX)
              }
            }
            work_image__short: work_image {
              asset {
                gatsbyImageData(layout: CONSTRAINED, height: 500, fit: FILLMAX)
              }
            }
            work_title
            artist {
              _id
              create_web_page
              first_name
              last_name
              yearOfDeath: date_of_death(formatString: "yyyy")
              yearOfBirth: date_of_birth(formatString: "yyyy")
            }
            work_date
          }

          featured_artwork {
            _id
            name
            work_image {
              asset {
                gatsbyImageData(layout: CONSTRAINED, height: 600, fit: FILLMAX)
              }
            }
            work_image__short: work_image {
              asset {
                gatsbyImageData(layout: CONSTRAINED, height: 500, fit: FILLMAX)
              }
            }
            work_title
            artist {
              _id
              create_web_page
              first_name
              last_name
              yearOfDeath: date_of_death(formatString: "yyyy")
              yearOfBirth: date_of_birth(formatString: "yyyy")
            }
            work_date
          }

          _rawDescription(resolveReferences: { maxDepth: 10 })
          homepage_name
          artworks {
            artist {
              create_web_page
              first_name
              last_name
              yearOfDeath: date_of_death(formatString: "yyyy")
              yearOfBirth: date_of_birth(formatString: "yyyy")
            }
            is_archived
            name
            work_title
            work_date
            work_image {
              asset {
                gatsbyImageData(layout: CONSTRAINED, height: 600, fit: FILLMAX)
              }
            }
            work_image__short: work_image {
              asset {
                gatsbyImageData(layout: CONSTRAINED, height: 500, fit: FILLMAX)
              }
            }
          }
        }

        ... on SanityViewingRoom {
          _id
          title
          homepage_title
          _type
          year: start_date(formatString: "YYYY")
          start_date(formatString: "Do MMMM YYYY")
          end_date(formatString: "Do MMMM YYYY")
          content: _rawContent(resolveReferences: { maxDepth: 10 })
          homepageContent: _rawHomepageContent(
            resolveReferences: { maxDepth: 10 }
          )
          artworks {
            artist {
              create_web_page
              first_name
              last_name
              yearOfDeath: date_of_death(formatString: "yyyy")
              yearOfBirth: date_of_birth(formatString: "yyyy")
            }
            is_archived
            name
            work_title
            work_date
            work_image {
              asset {
                gatsbyImageData(layout: CONSTRAINED, height: 600, fit: FILLMAX)
              }
            }
            work_image__short: work_image {
              asset {
                gatsbyImageData(layout: CONSTRAINED, height: 500, fit: FILLMAX)
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
